import { PropTypes } from "prop-types"
import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTwitter,
  faFacebook,
  faLine,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons"

const Footer = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      footerImage: file(relativePath: { eq: "pattern.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__about">
          <div className="logo">
            <Link to="/" className="logo__link">
              <Img
                fluid={data.logo.childImageSharp.fluid}
                className="logo__img"
              />
              <div className="logo__title">
                <h1 className="site__title">{siteTitle}</h1>
              </div>
            </Link>
          </div>

          <div className="footer__address">
            <address className="footer__myaddr">
              <p className="footer__zip">〒541−0051</p>
              <p className="footer__addr">大阪市中央区備後町４丁目１番３号</p>
              <p className="footer__addr__build">御堂筋三井ビルディングB1F</p>
              <p className="footer__tel-fax">
                <span className="footer__tel">TEL:06-6222-7744</span>
                <span className="footer__fax">FAX:06-6222-7745</span>
              </p>
            </address>
          </div>
        </div>
        <nav className="footer__sitemap">
          <ul>
            <li>
              <Link to={"/about/"}>事務所概要</Link>
            </li>
            <li>
              <Link to={"/consul/"}>相続・遺言・信託</Link>
            </li>
            <li>
              <Link to={"/regist/"}>抹消・住所変更</Link>
            </li>
            <li>
              <Link to={"/corp/"}>会社登記</Link>
            </li>
            <li>
              <Link to={"/blog/"}>ブログ</Link>
            </li>
            <li>
              <Link to={"/contact/"}>お問い合わせ</Link>
            </li>
            <li>
              <Link to={"/questions/"}>Ｑ＆Ａ</Link>
            </li>
            <li>
              <Link to={"/recruit/"}>採用情報</Link>
            </li>
          </ul>
        </nav>
        <div className="footer__sns">
          <ul className="sns">
            <li>
              <a
                href="https://twitter.com/Mitsuwaoff"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faTwitter} />
                <span className="sns__title">Twitter</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/mitsuwa_off/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} />
                <span className="sns__title">Instagram</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/mitsuwaoffice"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebook} />
                <span className="sns__title">Facebook</span>
              </a>
            </li>
            <li>
              <a
                href="https://lin.ee/ssadZrP"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLine} />
                <span className="sns__title">友だち追加</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="back">
        <Img
          fluid={data.footerImage.childImageSharp.fluid}
          style={{ height: "100%" }}
        />
      </div>
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
