import PropTypes from "prop-types"
import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 60) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [mobileMenuApper, setMobileMenuApper] = useState(false)

  const mobileMenuOpen = e => {
    e.preventDefault()
    setMobileMenuApper(!mobileMenuApper)
  }

  return (
    <>
      <div
        className={`${
          mobileMenuApper ? `mobile-menu-nav appear` : "mobile-menu-nav"
        }`}
        onClick={mobileMenuOpen}
        onKeyDown={mobileMenuOpen}
        role="presentation"
      >
        <ul>
          <li>
            <Link to={"/"}>トップページ</Link>
          </li>
          <li>
            <Link to={"/about/"}>事務所概要</Link>
          </li>
          <li>
            <Link to={"/consul/"}>相続・遺言・家族信託</Link>
          </li>
          <li>
            <Link to={"/regist/"}>抵当権抹消・住所変更・贈与</Link>
          </li>
          <li>
            <Link to={"/corp/"}>会社設立・会社役員変更</Link>
          </li>
          <li>
            <Link to={"/blog/"}>お知らせ・ブログ一覧</Link>
          </li>
          <li>
            <Link to={"/contact/"}>お問い合わせ</Link>
          </li>
          <li>
            <Link to={"/questions/"}>Ｑ＆Ａ</Link>
          </li>
          <li>
            <Link to={"/recruit/"}>採用情報</Link>
          </li>
        </ul>
      </div>
      <header className="header">
        <div className="header__container">
          <div className="logo">
            <Link to="/" className="logo__link">
              <Img
                fluid={data.placeholderImage.childImageSharp.fluid}
                className="logo__img"
              />
              <div className="logo__title">
                <h1 className="site__title">{siteTitle}</h1>
              </div>
            </Link>
          </div>

          <div
            className={`${
              mobileMenuApper ? `mobile-menu__btn appear` : "mobile-menu__btn"
            }`}
            onClick={mobileMenuOpen}
            role="presentation"
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <nav className="nav">
            <ul>
              <li>
                <Link to={"/about/"}>事務所概要</Link>
              </li>
              <li>
                <Link to={"/consul/"}>相続・遺言・信託</Link>
              </li>
              <li>
                <Link to={"/regist/"}>抹消・住所変更</Link>
              </li>
              <li>
                <Link to={"/corp/"}>会社登記</Link>
              </li>
              <li>
                <Link to={"/blog/"}>ブログ</Link>
              </li>
              <li>
                <Link to={"/contact/"}>お問い合わせ</Link>
              </li>
              <li>
                <Link to={"/questions/"}>Ｑ＆Ａ</Link>
              </li>
              <li>
                <Link to={"/recruit/"}>採用情報</Link>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
