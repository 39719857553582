import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({
  description,
  lang,
  meta,
  title,
  pagepath,
  pageimg,
  pageimgw,
  pageimgh,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            locale
            fbappid
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const url = pagepath
    ? `${site.siteMetadata.siteUrl}${pagepath}`
    : site.siteMetadata.siteUrl

  const imgurl = pageimg
    ? `${site.siteMetadata.siteUrl}${pageimg}`
    : `${site.siteMetadata.siteUrl}/static/8fe337b802643b54ea94e852d4e512af/14b42/hero.jpg`

  const imgw = pageimgw || 800
  const imgh = pageimgh || 448

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title !== "" ? `${title} | ${defaultTitle}` : defaultTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:site_name`,
          content: defaultTitle,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          property: `og:locale`,
          content: site.siteMetadata.locale,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `fb:app_id`,
          content: site.siteMetadata.fbappid,
        },
        {
          property: `og:image`,
          content: imgurl,
        },
        {
          property: `og:image:width`,
          content: imgw,
        },
        {
          property: `og:image:height`,
          content: imgh,
        },
      ].concat(meta)}
    >
      <link rel="canonical" href={url} />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `ja`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
